import AddIcon from "@mui/icons-material/Add";
import {projectActions} from "../../store";
import { Box, Fab, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Sidebar from "../global/Sidebar";
import { tokens } from "../../theme";
import Topbar from "../global/Topbar";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";

import * as React from "react";
import Moment from "moment/moment";
import Modal from "@mui/material/Modal";

const fabStyle = {
    backgroundColor: "#1361A1",
    bottom: 110,
    position: "absolute",
    right: 45,
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};


const Projects = () => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isSidebar, setIsSidebar] = useState(true);

    const [anchorEl, setAnchorEl] = useState(null);
    const isRowMenuOpen = Boolean(anchorEl);
    const [selectedProject, setSelectedProject] = useState(null);
    const openRowMenu = (event) => {
        const projectId = parseInt(
            event.currentTarget.getAttribute("data-project-id")
        );
        const project = projectList.filter(
            (project) => project.id === projectId
        )[0];
        setSelectedProject(project);
        setAnchorEl(event.currentTarget);
    };

    const closeRowMenu = () => {
        setAnchorEl(null);
    };


    const columns = [
        {
            field: "id",
            headerName: "ID",
            headerAlign: "center",
            headerClassName: "super-app-theme--header",
            align: "right",
            width: 50,
        },
        {
            field: "name",
            headerName: "Project Name",
            headerAlign: "left",
            headerClassName: "super-app-theme--header",
            width: 200,
        },
        {
            field: "bucketName",
            headerName: "Bucket Name",
            headerAlign: "left",
            headerClassName: "super-app-theme--header",
            align: "left",
            width: 200,
        },
        {
            field: "directory",
            headerName: "Directory",
            headerAlign: "left",
            headerClassName: "super-app-theme--header",
            align: "left",
            width: 200,
        },
        {
            field: "unitSystem",
            headerName: "Unit System",
            headerAlign: "left",
            headerClassName: "super-app-theme--header",
            align: "left",
            width: 200,
        },
        {
            field: "lastModificationTime",
            headerName: "Modification Time",
            headerAlign: "left",
            headerClassName: "super-app-theme--header",
            align: "left",
            width: 200,
            valueFormatter: params => params.value ? new Moment(params.value).utc(false).format('L')  + " " + new Moment(params.value).utc(false).format('LTS') : "",
        },
        {
            field: "actions",
            headerName: "",
            headerClassName: "super-app-theme--header",
            align: "right",
            menu: "menu",
            width: 50,
            renderCell: (params) => {
                const projectId = params.row.id;
                return (
                    <MoreVertIcon
                        data-project-id={projectId}
                        onClick={openRowMenu}
                        sx={{ cursor: "pointer" }}
                    />
                );
            },
        },
    ];
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [rowCount, setRowCount] = useState(0);
    const [projectList, setProjectList] = useState([]);

    useEffect(() => {
        const pageable = { size: pageSize, page: page };
        setIsLoading(true);

        dispatch(projectActions.getProjectPage({pageable})).then((state) => {
            if (state.payload) {
                setProjectList(state.payload.content);
                setRowCount(state.payload.page.totalElements);
            }

            setIsLoading(false);
        });
    }, [dispatch, page, pageSize]);

    // Actions {{
    const createProject = () => {
        closeRowMenu();
        handleOpen();
    };

    const viewProject = () => {
        closeRowMenu();
        handleOpen();
    };

    const updateProject = () => {
        closeRowMenu();
        handleOpen();
    };

    return (
        <div className="app">
            <Sidebar isSidebar={isSidebar} />
            <main className="content">
                <Topbar setIsSidebar={setIsSidebar} />
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Edit Contact Details
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <div className="edit-container">
                                <label>Edit Contact Name</label>
                                <input type="text" />
                                <label>Edit Contact Email</label>
                                <input type="text" />
                                <label>Edit Contact Image</label>
                                <input type="file" />
                            </div>

                            <button class="edit-btn">Save</button>
                        </Typography>
                    </Box>
                </Modal>
                <Box sx={{ mb: 4, ml: 3, mr: 3, mt: 4 }}>
                    <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, 1fr)">
                        <Typography
                            align="center"
                            component="h2"
                            gridColumn="span 12"
                            variant="h2"
                        >
                            Projects
                        </Typography>
                        <Box
                            backgroundColor={colors.primary[400]}
                            gridColumn="span 12"
                            gridRow="span 2"
                            sx={{
                                height: "calc(100vh - 200px)",
                                width: "100%",
                                "& .MuiDataGrid-columnHeaders": {
                                    backgroundColor: "#1361A1",
                                    color: "#FFFFFF",
                                },
                                "& .MuiDataGrid-columnSeparator path": {
                                    display: "none",
                                },
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    fontWeight: "bold",
                                },
                                "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
                                    color: "#FFFFFF",
                                },
                            }}
                        >
                            <DataGrid
                                columns={columns}
                                loading={isLoading}
                                onPageChange={(newPage) => setPage(newPage)}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                page={page}
                                pageSize={pageSize}
                                pagination
                                paginationMode="server"
                                rowCount={rowCount}
                                rows={projectList}
                                rowsPerPageOptions={[2, 5, 10, 25]}
                            />
                            <Menu
                                anchorEl={anchorEl}
                                onClose={closeRowMenu}
                                open={isRowMenuOpen}
                            >
                                <MenuItem onClick={viewProject}>
                                    <VisibilityIcon fontSize="small" />
                                    &nbsp; View
                                </MenuItem>
                                <MenuItem onClick={updateProject}>
                                    <EditIcon fontSize="small" />
                                    &nbsp; Update
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Box>
                <Fab aria-label="add" color="primary" sx={fabStyle}>
                    <AddIcon onClick={createProject} />
                </Fab>
            </main>
        </div>
    );
};

export default Projects;

import AddIcon from "@mui/icons-material/Add";
import { adminActions } from "../../store";
import { Box, Chip, Fab, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Sidebar from "../global/Sidebar";
import { tokens } from "../../theme";
import Topbar from "../global/Topbar";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

import * as React from "react";

const roleColor = (roleName) => {
  switch (roleName) {
    case "SystemAdmin":
      return "error";
    case "Manufactory":
      return "warning";
    case "Admin":
      return "info";
    default:
      return "success";
  }
};

const fabStyle = {
  backgroundColor: "#1361A1",
  bottom: 110,
  position: "absolute",
  right: 45,
};

const Accounts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);

  // Row Menu {{
  const [anchorEl, setAnchorEl] = useState(null);
  const isRowMenuOpen = Boolean(anchorEl);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const openRowMenu = (event) => {
    const accountId = parseInt(
      event.currentTarget.getAttribute("data-account-id")
    );
    const account = accountList.filter(
      (account) => account.id === accountId
    )[0];
    setSelectedAccount(account);
    setAnchorEl(event.currentTarget);
  };
  const closeRowMenu = () => {
    setAnchorEl(null);
  };
  // }} Row Menu

  // DataGrid {{
  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "right",
      width: 50,
    },
    {
      field: "firstName",
      headerName: "First Name",
      headerAlign: "left",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      headerAlign: "left",
      headerClassName: "super-app-theme--header",
      align: "left",
      width: 150,
    },
    {
      field: "userName",
      headerName: "Username",
      headerAlign: "left",
      headerClassName: "super-app-theme--header",
      align: "left",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "left",
      headerClassName: "super-app-theme--header",
      align: "left",
      width: 300,
    },
    {
      field: "roleList",
      headerName: "Role",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
      width: 150,
      renderCell: (params) => {
        return (
          <Chip
            color={roleColor(params.row.roleList[0])}
            label={params.row.roleList[0]}
            sx={{ borderWidth: "2px", fontWeight: "bold" }}
            variant="outlined"
          />
        );
      },
    },
    {
      field: "accountStatus",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "actions",
      headerName: "",
      headerClassName: "super-app-theme--header",
      align: "right",
      menu: "menu",
      width: 50,
      renderCell: (params) => {
        const accountId = params.row.id;
        return (
          <MoreVertIcon
            data-account-id={accountId}
            onClick={openRowMenu}
            sx={{ cursor: "pointer" }}
          />
        );
      },
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [accountList, setAccountList] = useState([]);

  useEffect(() => {
    const pageable = { size: pageSize, page: page };
    setIsLoading(true);

    dispatch(adminActions.accountList({ pageable })).then((state) => {
      if (state.payload) {
        setAccountList(state.payload.content);
        setRowCount(state.payload.page.totalElements);
      }

      setIsLoading(false);
    });
  }, [dispatch, page, pageSize]);
  // }} DataGrid

  // Actions {{
  const createAccount = () => {
    closeRowMenu();
    const path = `/admin/accounts/create`;
    navigate(path);
  };

  const viewAccount = () => {
    closeRowMenu();
    const path = `/admin/${selectedAccount.projectList[0]?.id}/accounts/${selectedAccount.id}`;
    navigate(path);
  };

  const updateAccount = () => {
    closeRowMenu();
    const path = `/admin/${selectedAccount.projectList[0]?.id}/accounts/${selectedAccount.id}/update`;
    navigate(path);
  };

  const showAccountProjects = () => {
    closeRowMenu();
    const path = `/admin/${selectedAccount.projectList[0]?.id}/accounts/${selectedAccount.id}/projects`;
    navigate(path);
  };

  const deleteAccount = () => {
    closeRowMenu();
    const path = `/admin/${selectedAccount.projectList[0]?.id}/accounts/${selectedAccount.id}/delete`;
    navigate(path);
  };
  // }} Actions

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box sx={{ mb: 4, ml: 3, mr: 3, mt: 4 }}>
          <Box display="grid" gap="20px" gridTemplateColumns="repeat(12, 1fr)">
            <Typography
              align="center"
              component="h2"
              gridColumn="span 12"
              variant="h2"
            >
              Accounts
            </Typography>
            <Box
              backgroundColor={colors.primary[400]}
              gridColumn="span 12"
              gridRow="span 2"
              sx={{
                height: "calc(100vh - 200px)",
                width: "100%",
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#1361A1",
                  color: "#FFFFFF",
                },
                "& .MuiDataGrid-columnSeparator path": {
                  display: "none",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
                  color: "#FFFFFF",
                },
              }}
            >
              <DataGrid
                columns={columns}
                loading={isLoading}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                page={page}
                pageSize={pageSize}
                pagination
                paginationMode="server"
                rowCount={rowCount}
                rows={accountList}
                rowsPerPageOptions={[2, 5, 10, 25, 50]}
              />
              <Menu
                anchorEl={anchorEl}
                onClose={closeRowMenu}
                open={isRowMenuOpen}
              >
                <MenuItem onClick={viewAccount}>
                  <VisibilityIcon fontSize="small" />
                  &nbsp; View
                </MenuItem>
                <MenuItem onClick={updateAccount}>
                  <EditIcon fontSize="small" />
                  &nbsp; Update
                </MenuItem>
                <MenuItem onClick={showAccountProjects}>
                  <FormatListNumberedIcon fontSize="small" />
                  &nbsp; Projects
                </MenuItem>
                <MenuItem onClick={deleteAccount}>
                  <DeleteForeverIcon fontSize="small" />
                  &nbsp; Delete
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
        <Fab aria-label="add" color="primary" sx={fabStyle}>
          <AddIcon onClick={createAccount} />
        </Fab>
      </main>
    </div>
  );
};

export default Accounts;

import * as React from 'react';
import GenVibrationTabPanel from "./GenVibrationTabPanel";
import {useEffect} from "react";
import GenTWFPlot from "./GenTWFPlot";
import GenComparePlot from "./GenComparePlot";
import GenFFTPlot from "./GenFFTPlot";
import GenFFTvelPlot from "./GenFFTvelPlot";

export default function GenVibrationTabs({ isDashboard, nodeId, projectName, graphType, setGraphType, testType }) {
    const [tabId, setTabId] = React.useState(0);
    const [collectionTime, setCollectionTime] = React.useState(null);

    useEffect(() => {
    }, [collectionTime, graphType]);

    if (!graphType) {
        return (
            <GenVibrationTabPanel
                isDashboard={isDashboard}
                nodeId={nodeId}
                projectName={projectName}
                tabId={tabId}
                setTabId={setTabId}
                setGraphType={setGraphType}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
                testType={testType}
            />
        )
    } if (graphType === 'plot') {
        return (
            <GenTWFPlot
                nodeId={nodeId}
                projectName={projectName}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
                setGraphType={setGraphType}
            />
        )
    } else if (graphType === 'fft') {
        return (
            <GenFFTPlot
                nodeId={nodeId}
                projectName={projectName}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
                setGraphType={setGraphType}
            />
        )
    } else if (graphType === 'fftvel') {
        return (
            <GenFFTvelPlot
                nodeId={nodeId}
                projectName={projectName}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
                setGraphType={setGraphType}
            />
        )
    } else if (graphType === 'compare') {
        return (
            <GenComparePlot
                nodeId={nodeId}
                projectName={projectName}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
                setGraphType={setGraphType}
            />
        )
    }
}

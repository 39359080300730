import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import VibrationComponent from "../vibration/VibrationComponent";
import GenVibrationComponent from "../vibration_general/GenVibrationComponent";
import ThermalComponent from "../thermal/ThermalComponent";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import VisualComponent from "../Visual/VisualComponent";
import {Air, ElectricMeter, SurroundSound} from "@mui/icons-material";
import TEFComponent from "../tef/TEFComponent";
import ElectricalComponent from "../electrical/ElectricalComponent";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function TefVibrationTest({
                                              isDashboard,
                                              nodeId,
                                              projectName,
                                              graphType,
                                              setGraphType,
                                              setCollectionTime,
                                              setTabId,
                                              tabId = 0
                                          }) {
    const handleChange = (event, newValue) => {
        setTabId(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                    value={tabId}
                    onChange={handleChange}
                    aria-label="Collection Time"
                    textColor="primary"
                    indicatorColor="secondary"
                    variant="scrollable">
                    orientation="vertical"
                    <Tab
                        icon={<InsertChartIcon/>}
                        label="TE Flaps Vibration" {...a11yProps(0)} />
                </Tabs>
            </Box>

            <TabPanel value={tabId} index={0}>
                <VibrationComponent
                    isDashboard={isDashboard}
                    nodeId={nodeId}
                    projectName={projectName}
                    setCollectionTime={setCollectionTime}
                    graphType={graphType}
                    setGraphType={setGraphType}
                    testType=''
                />
            </TabPanel>
        </Box>
    );
}
